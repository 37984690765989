// EXEMPT
import { initEvents } from "@/__main__/ipc-core.mjs";
import mainRefs from "@/__main__/refs.mjs";
import type { AnyGameDefinition } from "@/app/game-definition.mjs";
import gamesList from "@/app/games.mjs";
import initIPCGeneric from "@/app/init-ipc.mjs";
import lazyFn from "@/util/lazy-fn.mjs";

// Used for integration testing.
export * as lolClient from "@/game-lol/utils/lol-client.mjs";

const initFns = [
  initIPCGeneric,
  ...gamesList
    .filter(({ IPC }: AnyGameDefinition) => IPC)
    .map(({ IPC }: AnyGameDefinition) => {
      return lazyFn(IPC);
    }),
];

(async () => {
  await initEvents;
  await mainRefs.appInit;
  for (const fn of initFns) {
    fn();
  }
})();
